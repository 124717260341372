import BeableBase from "../BeableBase";
import Header from "../../components/Skus/Header";
import {Box, Button, Link, Typography} from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MaintenanceOrdersTable from "../../components/Maintenance/MaintenanceOrdersTable";
import axiosInstance from "../../utils/axiosInstance";
import {useEffect, useState} from "react";
import CreateMaintenanceOrderDialog from "../../components/Maintenance/CreateMaintenanceOrderDialog";

export default function MaintenanceOrdersPage({setIsLoggedIn}) {
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [canLoadTable, setCanLoadTable] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const pageSize = 16;

    const fetchOrders = async () => {
        try {
            const response = await axiosInstance.get(`maintenance/search_orders?page=${currentPage}&pageSize=${pageSize}`);

            setOrders(response.data.maintenanceOrders);
            setTotalPages(response.data.totalPages);

            console.log(response.data)
        } catch (e) {
            console.error(e)
        }
    }

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>Ordens de manutenção</Typography>
        </Link>,
    ];

    const handleClickCreateOrder = () => {
        setOpen(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchOrders();
            setCanLoadTable(true);
        }

        fetchData();
    }, []);

    return (
        <BeableBase indexSelected={5} setIsLoggedIn={setIsLoggedIn}>
            <Header linkItems={path} />
            <Box
                sx={{
                    width: "100%",
                    mt: 4,
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Typography level="h2">
                    Ordens de manutenção
                </Typography>

                <Button size={"sm"} onClick={handleClickCreateOrder}>
                    Criar ordem de manutenção
                </Button>
            </Box>
            <Box>
                <MaintenanceOrdersTable orders={orders} fetchOrders={fetchOrders} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
            </Box>
            <CreateMaintenanceOrderDialog open={open} setOpen={setOpen} fetchOrders={fetchOrders}/>
        </BeableBase>
    );
}