import BeableBase from "./BeableBase";
import {Box, Button, Link, Typography} from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Header from "../components/Skus/Header";
import CreateEquipmentDialog from "../components/Equipments/CreateEquipmentDialog";
import {useEffect, useState} from "react";
import axiosInstance from "../utils/axiosInstance";
import EquipmentsTable from "../components/Equipments/EquipmentsTable";

export default function EquipmentPages({ setIsLoggedIn }) {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [canLoadPage, setCanLoadPage] = useState(false);
    const [productionLines, setProductionLines] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>Equipamentos</Typography>
        </Link>,
    ];

    const handleOpenCreateEquipment = () => {
        setOpenCreateDialog(true);
    }

    const fetchProductionLines = async () => {
        try {
            const response = await axiosInstance.get("/linhas/get_linhas");

            setProductionLines(response.data);
        } catch (error) {
            console.error("Error fetching production lines:", error);
        }
    }

    const fetchEquipments = async (page, pageSize) => {
        try {
            const response = await axiosInstance.get(`/equipments/search?name=&productionLineId=&page=${page}&pageSize=${pageSize}`);

            setEquipments(response.data.equipments);
        } catch (error) {
            console.error("Error fetching equipments:", error);
        }
    }

    useEffect(() => {
        const getInitialInfo = async () => {
            await fetchEquipments(1, 16);
            await fetchProductionLines();
            setCanLoadPage(true);
        }

        getInitialInfo();
    }, []);


    return (
        <BeableBase indexSelected={20} setIsLoggedIn={setIsLoggedIn}>
            <Header linkItems={path} />
            <Box
                sx={{
                    width: "100%",
                    mt: 4,
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Typography level="h2">
                    Equipamentos
                </Typography>
                <Button
                    onClick={() => { handleOpenCreateEquipment() }}
                    size="md"
                    sx={{
                        maxHeight: "32px",
                        minHeight: "32px",
                    }}
                >
                    Criar Equipamento
                </Button>
            </Box>
            { canLoadPage && (
                <>
                    <EquipmentsTable
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        equipments={equipments}
                        fetchEquipments={fetchEquipments}
                    />
                    <CreateEquipmentDialog
                        open={openCreateDialog}
                        setOpen={setOpenCreateDialog}
                        lines={productionLines}
                        fetchEquipments={fetchEquipments}
                        currentPage={currentPage} 
                    />
                </>
            )}
        </BeableBase>
    );
}

