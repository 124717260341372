import {Chip, Dropdown, ListItemDecorator, Menu, MenuButton, MenuItem, Sheet, Table, Typography} from "@mui/joy";
import {ConvertToReadableDateNormal} from "../../utils/ConvertToReadableDate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlanDialog from "../Maintenance/PlanDialog";
import Pagination from "../Skus/Pagination";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import React from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useNavigate} from "react-router-dom";

function EquipmentsTable({equipments, fetchEquipments, currentPage, setCurrentPage, totalPages}) {
    const navigate = useNavigate();

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    mt: 3,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}>
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                    }}>
                    <thead>
                    <tr>
                        <th style={{width: "15%"}}>Código</th>
                        <th style={{width: "45%"}}>Nome</th>
                        <th style={{width: "30%"}}>Linha de Produção</th>
                        <th style={{width: "10%", textAlign: "right"}}>Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    {equipments.map((equipment, index) => (
                        <tr style={{height: "38px"}} key={index}>
                            <td>{equipment.code}</td>
                            <td>{equipment.name}</td>
                            <td>
                                <Chip size={"sm"} color={equipment.productionLineName !== null ? "primary" : "danger"}>
                                    {equipment.productionLineName !== null ? equipment.productionLineName : "Sem linha associada"}
                                </Chip>
                            </td>
                            <td style={{textAlign: "right"}}>
                                <Dropdown>
                                    <MenuButton
                                        variant="plain"
                                        size="sm"
                                        sx={{
                                            maxWidth: "30px",
                                            maxHeight: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                        }}
                                    >
                                        <MoreVertIcon/>
                                    </MenuButton>
                                    <Menu placement={"bottom-end"}>
                                        <MenuItem onClick={() => console.log("Edit")}>
                                            <ListItemDecorator>
                                                <EditOutlinedIcon fontSize="small"/>
                                            </ListItemDecorator>
                                            <Typography fontSize="0.8rem">Editar</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate(`plano_manutencao?code=${equipment.code}`)}>
                                            <ListItemDecorator>
                                                <AssignmentOutlinedIcon fontSize="small"/>
                                            </ListItemDecorator>
                                            <Typography fontSize="0.8rem">Ver plano</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Sheet>
            <Pagination fetchData={fetchEquipments} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </>
    )
}

export default EquipmentsTable;