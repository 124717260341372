import {
    Box,
    Button,
    DialogContent,
    DialogTitle, Divider,
    Modal,
    ModalDialog,
    Sheet,
    Typography
} from "@mui/joy";
import React, {useEffect, useState} from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelIcon from '@mui/icons-material/Cancel';
import MaterialDetailsInput from "./MaterialDetailsInput";
import axiosInstance from "../../utils/axiosInstance";
import MaterialDetailsAutocomplete from "./MaterialsDetailsAutocomplete";

export default function ViewMaterialDetailsDialog({ open, setOpen, material, setMaterial, setSnackbarOpen, fetchMaterials, materialType }) {
    const [canEdit, setCanEdit] = useState(false);
    const [body, setBody] = useState({ description: '', safetyStock: '', cost: '', didExist: false});
    const [manufacturers, setManufacturers] = useState([{id: null, name: "", code: "", didExist: false}]);
    const [components, setComponents] = useState([{materialComponentId: null, componentId: null, name: "", didExist: false}]);
    const [manufacturersOptions, setManufacturersOptions] = useState([]);
    const [componentOptions, setComponentOptions] = useState([]);
    const [canLoad, setCanLoad] = useState(false);


    const fetchComponentOptions = async () => {
        try {
            const response = await axiosInstance.get("components/get_all");

            setComponentOptions(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    const fetchManufacturerOptions = async () => {
        try {
            const response = await axiosInstance.get("manufacturers/get_manufacturers");

            setManufacturersOptions(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    const handleClose = () => {
        setOpen(false);
        setCanEdit(false)
        setMaterial({ description: '', safetyStock: '', cost: '', manufacturers: [], components: [] });
    }

    const handleClickEdit = () => {
        setCanEdit(true);
    }

    const handleAddManufacturer = () => {
        setManufacturers((prevManufacturers) => {
            return [...prevManufacturers, {name: "", code: ""}];
        });
    }

    const handleRemoveManufacturer = (index) => {
        setManufacturers((prevManufacturers) => {
            const updatedManufacturers = [...prevManufacturers];
            updatedManufacturers.splice(index, 1);
            return updatedManufacturers;
        });
    }

    const handleAddComponents = () => {
        setComponents((prevComponents) => {
            return [...prevComponents, {name: ""}];
        });
    }

    const handleRemoveComponent = (index) => {
        setComponents((prevComponents) => {
            const updatedComponents = [...prevComponents];
            updatedComponents.splice(index, 1);
            return updatedComponents;
        });
    }

    const handleEditBody = (field, value) => {
        setBody({
            ...body,
            [field]: value
        });
    }

    const handleEditManufacturer = (index, field, value) => {
        setManufacturers((prevManufacturers) => {
            const updatedManufacturers = [...prevManufacturers];
            updatedManufacturers[index][field] = value;
            return updatedManufacturers;
        });
    }

    const handleEditComponent = (index, value) => {
        setComponents((prevComponents) => {
            const updatedComponents = [...prevComponents];
            updatedComponents[index]["name"] = value.name;
            updatedComponents[index]["componentId"] = value.id;
            return updatedComponents;
        });
    }

    const handleSave = async (e) => {
        e.preventDefault();

        const editManufactures = manufacturers.filter((m) => m.didExist).map((m) => {
            return {id: m.id, name: m.name, code: m.code};
        });

        const newManufacturers = manufacturers.filter((m) => !m.didExist).map((m) => {
            return {manufacturerName: m.name, manufacturerCode: m.code};
        });

        const editComponents = components.filter((c) => c.didExist).map((c) => {
            return {materialComponentId: c.materialComponentId, componentId: c.componentId};
        });
        //
        const newComponents = components.filter((c) => !c.didExist).map((c) => {
            return c.componentId;
        })

        const putBody = {
            MaterialId: material.id,
            MaterialType: materialType,
            Description: body.description,
            SafetyStock: body.safetyStock,
            Cost: body.cost,
            EditManufacturers: editManufactures,
            NewManufacturers: newManufacturers,
            EditComponents: editComponents,
            NewComponents: newComponents
        }

        try {
            const response = await axiosInstance.put("materials/update_material", putBody)

            if (response.status === 200) {
                setMaterial({
                    id: material.id,
                    name: body.description,
                    safetyStock: body.safetyStock,
                    cost: body.cost,
                    manufacturers: manufacturers,
                    components: components
                });
                setCanEdit(false);
                fetchMaterials();
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const fetchOptions = async () => {
            fetchComponentOptions();
            fetchManufacturerOptions();
        };

        fetchOptions();
        if (material) {
            setBody({
                description: material.name || '',
                safetyStock: material.safetyStock || 0,
                cost: material.cost || 0,
            });

            setManufacturers((material.manufacturers || []).map((m) => {
                return { id: m.id, name: m.name, code: m.code, didExist: true };
            }));

            setComponents((material.components || []).map((c) => {
                return { materialComponentId: c.materialComponentId, componentId: c.componentId, name: c.name, didExist: true };
            }));

            setCanLoad(true);

            console.log(material)
        }
    }, [material]);

    if (canLoad === true) {
        return (
            <Sheet>
                <Modal
                    open={open}
                    onClose={() => handleClose()}
                    keepMounted={false}
                >
                    <ModalDialog sx={{
                        maxHeight: "80vh",
                        maxWidth: "60vw",
                        minWidth: "38vw",
                        pr: 0,
                        pl: 3.5
                    }}>
                        <DialogTitle>
                            Detalhes do material
                        </DialogTitle>
                        <DialogContent sx={{
                            scrollbarGutter: "stable",
                            pr: 1,
                        }}>
                            <form id={"update-form"} onSubmit={handleSave}>
                                <Box sx={{display: "flex", flexDirection: "column", mt: 3, gap: 1.6}}>

                                    {/* Material Code */}
                                    <MaterialDetailsInput
                                        inputValue={material.code}
                                        canEdit={canEdit}
                                        text={"Código do material"}
                                        disabled={true}
                                    />

                                    {/* Material Description */}
                                    <MaterialDetailsInput
                                        inputValue={body.description}
                                        canEdit={canEdit}
                                        text={"Descricão do material"}
                                        startDecorator={<ModeEditOutlinedIcon/>}
                                        disabled={!canEdit}
                                        onChange={(event) => handleEditBody("description", event.target.value)}
                                        type={"text"}
                                    />


                                    {/* In stock */}
                                    <MaterialDetailsInput
                                        inputValue={material.stock}
                                        canEdit={canEdit}
                                        text={"Em stock"}
                                        disabled={true}
                                    />

                                    {/* Safety Stock */}
                                    <MaterialDetailsInput
                                        inputValue={body.safetyStock}
                                        canEdit={canEdit}
                                        text={"Stock de segurança"}
                                        startDecorator={<ModeEditOutlinedIcon/>}
                                        disabled={!canEdit}
                                        onChange={(event) => handleEditBody("safetyStock", event.target.value)}
                                        type={"number"}
                                    />

                                    {/* Cost */}
                                    <MaterialDetailsInput
                                        inputValue={body.cost}
                                        canEdit={canEdit}
                                        text={"Cost (€)"}
                                        startDecorator={<ModeEditOutlinedIcon/>}
                                        disabled={!canEdit}
                                        onChange={(event) => handleEditBody("cost", event.target.value)}
                                        type={"number"}
                                    />

                                    <Divider sx={{
                                        mt: 1.5,
                                        mb: 1,
                                        '--Divider-childPosition': `0%`,
                                    }}>
                                        <Typography
                                            sx={{
                                                flex: 1,
                                                color: 'rgba(0, 0, 0, 0.8)'
                                            }}
                                            fontSize={"0.93rem"}
                                            level={"title-md"}
                                        >
                                            Fabricantes
                                        </Typography>
                                    </Divider>
                                    <Sheet>
                                        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                            {manufacturers.map((manufacturer, index) => (
                                                <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                                    <MaterialDetailsAutocomplete
                                                        freeSolo={true}
                                                        inputValue={manufacturer.name}
                                                        onInputChange={(event, value) => {
                                                            handleEditManufacturer(index, "name", value)
                                                        }}
                                                        value={manufacturer.name}
                                                        onChange={(event, value) => {
                                                            handleEditManufacturer(index, "name", value)
                                                        }}
                                                        canEdit={canEdit}
                                                        text={"Nome do fabricante"}
                                                        disabled={!canEdit}
                                                        startDecorator={<ModeEditOutlinedIcon/>}
                                                        options={manufacturersOptions.map((option) => option.name)}
                                                    />
                                                    <MaterialDetailsInput
                                                        inputValue={manufacturer.code}
                                                        onChange={(event) => {
                                                            handleEditManufacturer(index, "code", event.target.value)
                                                        }}
                                                        canEdit={canEdit}
                                                        text={"Código do fabricante"}
                                                        disabled={!canEdit}
                                                        startDecorator={<ModeEditOutlinedIcon/>}
                                                    />
                                                    {/* Handle Remove or Add Manufacturers*/}
                                                    { canEdit &&
                                                        (
                                                            <Box sx={{gap: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                                                                { manufacturers.length - 1 === index &&
                                                                    (
                                                                        <Button
                                                                            sx={{
                                                                                mb: 1,
                                                                                width: "auto",
                                                                                alignSelf: "flex-start",
                                                                                maxHeight: "31px",
                                                                                minHeight: "31px"
                                                                            }}
                                                                            size="sm"
                                                                            startDecorator={<AddOutlinedIcon />}
                                                                            variant="soft"
                                                                            onClick={() => {handleAddManufacturer()}}
                                                                        >
                                                                            Adicionar
                                                                        </Button>
                                                                    )
                                                                }
                                                                <Button
                                                                    sx={{
                                                                        width: "auto",
                                                                        maxHeight: "31px",
                                                                        minHeight: "31px"
                                                                    }}
                                                                    onClick={() => {handleRemoveManufacturer(index)}}
                                                                    size="sm"
                                                                    startDecorator={<AddOutlinedIcon />}
                                                                    variant="soft"
                                                                    color={"danger"}
                                                                    disabled={manufacturer.didExist}
                                                                >
                                                                    Remover
                                                                </Button>
                                                            </Box>
                                                        )
                                                    }
                                                    { manufacturers.length - 1 !== index && <Divider/>}
                                                </Box>
                                            ))}
                                        </Box>
                                    </Sheet>

                                    {/* Components */}
                                    { (canEdit || components.length > 0) &&
                                        <>
                                            <Divider sx={{ mt: 1.5, mb: 1, '--Divider-childPosition': `0%` }}>
                                                <Typography
                                                    sx={{
                                                        flex: 1,
                                                        color: 'rgba(0, 0, 0, 0.8)'
                                                    }}
                                                    fontSize={"0.93rem"}
                                                    level={"title-md"}
                                                >
                                                    Componentes
                                                </Typography>
                                            </Divider>

                                            <Sheet>
                                                <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                                    {components.map((component, index) => (
                                                        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                                            <MaterialDetailsAutocomplete
                                                                freeSolo={false}
                                                                value={componentOptions.find(option => option.id === component.componentId)}
                                                                onChange={(event, value) => {
                                                                    const componentId = value?.id !== undefined ? value.id : "";
                                                                    const componentName = value?.name !== undefined ? value.name : "";
                                                                    const component = { id: componentId, name: componentName };
                                                                    handleEditComponent(index, component);
                                                                }}
                                                                canEdit={canEdit}
                                                                text={"Nome do componente"}
                                                                disabled={!canEdit}
                                                                startDecorator={<ModeEditOutlinedIcon/>}
                                                                options={componentOptions}
                                                                getOptionLabel={(option) => option.name}
                                                            />

                                                            { canEdit &&
                                                                (
                                                                    <Box sx={{gap: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                                                                        { components.length - 1 === index &&
                                                                            (
                                                                                <Button
                                                                                    sx={{
                                                                                        width: "auto",
                                                                                        alignSelf: "flex-start",
                                                                                        maxHeight: "31px",
                                                                                        minHeight: "31px"
                                                                                    }}
                                                                                    size="sm"
                                                                                    startDecorator={<AddOutlinedIcon />}
                                                                                    variant="soft"
                                                                                    onClick={() => { handleAddComponents() }}
                                                                                >
                                                                                    Adicionar
                                                                                </Button>
                                                                            )
                                                                        }
                                                                        <Button
                                                                            sx={{
                                                                                width: "auto",
                                                                                alignSelf: "flex-start",
                                                                                maxHeight: "31px",
                                                                                minHeight: "31px"
                                                                            }}
                                                                            size="sm"
                                                                            startDecorator={<AddOutlinedIcon />}
                                                                            variant="soft"
                                                                            color={"danger"}
                                                                            disabled={component.didExist}
                                                                            onClick={() => { handleRemoveComponent(index)}}
                                                                        >
                                                                            Remover
                                                                        </Button>
                                                                    </Box>
                                                                )
                                                            }

                                                            { components.length - 1 !== index && <Divider/>}
                                                        </Box>
                                                    ))}
                                                    { components.length === 0 &&
                                                        (
                                                            <Box sx={{textAlign: "right"}}>
                                                                <Button
                                                                    sx={{
                                                                        width: "auto",
                                                                        alignSelf: "flex-start",
                                                                        maxHeight: "31px",
                                                                        minHeight: "31px"
                                                                    }}
                                                                    size="sm"
                                                                    startDecorator={<AddOutlinedIcon />}
                                                                    variant="soft"
                                                                    onClick={() => { handleAddComponents() }}
                                                                >
                                                                    Adicionar
                                                                </Button>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                            </Sheet>
                                        </>
                                    }
                                </Box>
                            </form>
                        </DialogContent>
                        <Box
                            sx={{

                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                pt: 3,
                                mr: 3.5
                            }}
                        >
                            <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
                                <Button
                                    variant={"soft"}
                                    color={"warning"}
                                    size={"sm"}
                                    disabled={canEdit}
                                    startDecorator={<ModeEditOutlinedIcon/>}
                                    onClick={() => {
                                        handleClickEdit()
                                    }}
                                >
                                    Editar
                                </Button>
                                <Button
                                    form={"update-form"}
                                    variant={"solid"}
                                    color={"primary"}
                                    size={"sm"}
                                    disabled={!canEdit}
                                    startDecorator={<SaveIcon/>}
                                    type={"submit"}
                                >
                                    Salvar
                                </Button>
                            </Box>
                            <Button
                                sx={{
                                    ".MuiButton-startDecorator": {position: "relative", top: 1.2}
                                }}
                                variant={"solid"}
                                color={"danger"}
                                size={"sm"}
                                startDecorator={<CancelIcon/>}
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Fechar
                            </Button>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Sheet>
        )
    }
}