import {
    Box,
    Button,
    Chip,
    Dropdown,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Sheet,
    Snackbar,
    Table,
    Typography
} from "@mui/joy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {useEffect, useState} from "react";
import MakeMaterialMovementOrderDialog from "./MakeMaterialMovementOrderDialog";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ViewMaterialDetailsDialog from "./ViewMaterialDetailsDialog";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';

export default function MaterialsTable({ materials, fetchMaterials, materialType }) {
    const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
    const [dialogMaterial, setDialogMaterial] = useState({id: 0, code: "", name: "", cost: "", manufacturers: [], stock: 0});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isMaterialDetailsOpen, setIsMaterialDetailsOpen] = useState(false);
    const [materialDetails, setMaterialDetails] = useState({});

    const handleAskMaterial = (material) => {
        setDialogMaterial(material);
        setIsOrderDialogOpen(true);
    }

    const handleViewMaterialDetails = (material) => {
        setIsMaterialDetailsOpen(true);
        setMaterialDetails(material);
    }

    return (
        <Sheet
          variant="outlined"
          sx={{
            mt: 3,
            display: { xs: "none", sm: "initial" },
            width: 1,
            borderRadius: "sm",
            overflow: "auto",
            mb: 3
          }}
        >
            <Table
            size="sm"
            stickyHeader
            sx={{
              "--TableCell-headBackground": "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
            >
            <thead>
            <tr>
                <th style={{width: "10%", verticalAlign: "middle"}}>Código</th>
                <th style={{width: "30%", verticalAlign: "middle"}}>Descrição Material</th>
                <th style={{width: "30%", verticalAlign: "middle"}}>Fabricantes</th>
                <th style={{width: "10%", verticalAlign: "middle", textAlign: 'right'}}>Stock</th>
                <th style={{width: "10%", verticalAlign: "middle", textAlign: 'right'}}>Custo (€)</th>
                <th style={{width: "10%", verticalAlign: "middle", textAlign: 'right'}}>Opções</th>
            </tr>
            </thead>
              <tbody>
                  {materials.map((material) => (
                      <tr key={material.id} style={{maxHeight: "38px", minHeight: "38px"}}>
                          <td style={{textAlign: 'left'}}>
                              <Button
                                  onClick={() => handleViewMaterialDetails(material)}
                                  color={"neutral"}
                                  variant={"plain"}
                                  sx={{
                                      fontSize: "12px",
                                      fontWeight: 500, // You can use "bold", "normal", or a numeric value like 700
                                      "&:hover": {
                                          backgroundColor: "transparent",
                                          color: "rgb(52, 98, 172, 1)",
                                          textDecoration: "underline"
                                      },
                                      maxHeight: "32px",
                                      minHeight: "32px",
                                      justifyContent: 'flex-start',
                                      padding: 0,
                                      textAlign: 'left',
                                      alignItems: "left"
                                  }}
                              >
                                  {material.code}
                              </Button>
                          </td>
                          <td>
                              <Button
                                  onClick={() => handleViewMaterialDetails(material)}
                                  color={"neutral"}
                                  variant={"plain"}
                                  sx={{
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      "&:hover": {
                                          backgroundColor: "transparent",
                                          color: "rgb(52, 98, 172, 1)",
                                          textDecoration: "underline"
                                      },
                                      maxHeight: "32px",
                                      minHeight: "32px",
                                      justifyContent: 'flex-start',
                                      padding: 0,
                                      textAlign: 'left',
                                      alignItems: "left"
                                  }}
                              >
                                  {material.name}
                              </Button>
                          </td>
                          <td>
                              <Box sx={{display: "flex", flexDirection: "row", gap: 0.5}}>
                                  {material.manufacturers?.map((manufacturer, index) => (
                                      <Chip key={index} variant="soft" size="sm" color="primary">
                                          {manufacturer.name}
                                      </Chip>
                                  ))}
                              </Box>
                          </td>
                          <td style={{textAlign: 'right'}}>{material.stock}</td>
                          <td style={{textAlign: 'right'}}>{material.cost}</td>
                          <td style={{textAlign: 'right'}}>
                              <Dropdown>
                                  <MenuButton
                                      variant="plain"
                                      size="sm"
                                      sx={{
                                          maxWidth: "30px",
                                          maxHeight: "30px",
                                          minWidth: "30px",
                                          minHeight: "30px",
                                      }}
                                  >
                                      <MoreVertIcon/>
                                  </MenuButton>
                                  <Menu
                                      placement="bottom-end"
                                      sx={{
                                          gap: 0.5,
                                          minWidth: "80px",
                                          maxWidth: "200px",
                                          minHeight: "40px",
                                          justifyContent: "flex-start"
                                      }}
                                      size="sm"
                                  >
                                      <MenuItem onClick={() => handleAskMaterial(material)}>
                                          <ListItemDecorator>
                                              <ShoppingCartOutlinedIcon fontSize="small"/>
                                          </ListItemDecorator>
                                          <Typography fontSize="0.8rem">Pedir material</Typography>
                                      </MenuItem>
                                      <MenuItem onClick={() => handleViewMaterialDetails(material)}>
                                          <ListItemDecorator>
                                              <AssignmentOutlinedIcon fontSize="small"/>
                                          </ListItemDecorator>
                                          <Typography fontSize="0.8rem">Ver detalhes</Typography>
                                      </MenuItem>
                                      <MenuItem >
                                          <ListItemDecorator>
                                              <QrCodeOutlinedIcon fontSize="small"/>
                                          </ListItemDecorator>
                                          <Typography fontSize="0.8rem">Código QR</Typography>
                                      </MenuItem>
                                  </Menu>
                              </Dropdown>
                          </td>
                      </tr>
                  ))}
              </tbody>
            </Table>
            <MakeMaterialMovementOrderDialog open={isOrderDialogOpen} setOpen={setIsOrderDialogOpen} material={dialogMaterial} setSnackbarOpen={setSnackbarOpen}/>
            <ViewMaterialDetailsDialog
                open={isMaterialDetailsOpen}
                setOpen={setIsMaterialDetailsOpen}
                material={materialDetails}
                setMaterial={setMaterialDetails}
                fetchMaterials={fetchMaterials}
                materialType={materialType}
            />
            <Snackbar
                variant="soft"
                color="success"
                autoHideDuration={6000}
                open={snackbarOpen}
                onClose={(event, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setSnackbarOpen(false);
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon fontSize="xl" />}
            >
                Pedido feito com sucesso
            </Snackbar>
        </Sheet>
    );
}
