import {Box, Input, Typography} from "@mui/joy";
import React from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const MaterialDetailsInput = ({text, canEdit, disabled, inputValue, startDecorator, onChange, type}) => {
    return (
        <Box sx={{justifyContent: "space-between", display: "flex", flexDirection: "row"}}>
            <Typography
                sx={{
                    flex: 2,
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
                fontSize={"0.93rem"}
                level={"body-md"}
            >
                {text}
            </Typography>
            <Input
                startDecorator={canEdit ? startDecorator : null}
                sx={{
                    boxShadow: "none",
                    flex: 3,
                    top: -3,
                    '& .Mui-disabled': {
                        fontWeight: 400,
                        fontSize: "0.93rem",
                        color: 'rgba(0, 0, 0, 1)',
                        textAlign: ( canEdit ? 'left' : 'right' ),

                    },
                    '& input[type=number]': {
                        // Hide the arrows in Chrome, Safari, Edge, Opera
                        '::-webkit-outer-spin-button': {
                            WebkitAppearance: 'none',
                            margin: 0,
                        },
                        '::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            margin: 0,
                        },
                        // Hide the arrows in Firefox
                        MozAppearance: 'textfield',
                    },
                }}
                size={"sm"}
                variant={"soft"}
                value={inputValue}
                disabled={disabled}
                onChange={onChange}
                required={true}
                type={type}
            />
        </Box>
    )
}

export default MaterialDetailsInput;