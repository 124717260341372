import {
    Alert,
    Box,
    Button, CircularProgress,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalDialog, ModalOverflow, Option, Select,
    Sheet, Typography
} from "@mui/joy";

import React, {useEffect, useState} from "react";
import {ConvertToReadableDateInverted} from "../../utils/ConvertToReadableDate";
    import AddMaterialFormComponent from "../Materials/AddMaterialFormComponent";
import {activityType, frequencyUnit, maintenanceType, Whos} from "../../Models/MaintenanceModels";
import axiosInstance from "../../utils/axiosInstance";
import GetAmountFromFrequencyUnit from "../../utils/GetAmountFromFrequencyUnit";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {useNavigate} from "react-router-dom";
import {Warning} from "@mui/icons-material";



export default function CreateMaintenanceOrderDialog({setOpen, open, fetchOrders, type, equipment, line}) {
    const [minDate, setMinDate] = useState("");
    const [materials, setMaterials] = useState([]);
    const [body, setBody] = useState({LinhaId: null, EquipmentId: null, Task: null, ActivityType: null, Who: null,
        FrequencyCode: null, InterventionTime: null, NumberOfPeople: null, PlanDate: "", MaintenanceType: null});
    const [linhas, setLinhas] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [frequencyValuesList, setFrequencyValuesList] = useState([]);
    const [frequencies, setFrequencies] = useState([]);

    const navigate = useNavigate();

    const handleSetBody = (field, value) => {
        setBody((prevState) => {
            const updatedBody = {...prevState};
            updatedBody[field] = value;
            return updatedBody;
        });

        if (field === "FrequencyUnit") {
            const amount = GetAmountFromFrequencyUnit(value);
            let valuesList = []
            for (let i = 0; i < amount; i++) {
                valuesList.push(i + 1);
            }
            setFrequencyValuesList(valuesList);
            if (body.Frequency > amount) {
                setBody((prevState) => {
                    const updatedBody = {...prevState};
                    updatedBody.Frequency = amount;
                    return updatedBody;
                })
            }
        }
    }

    const handleOnClose = () => {
        setOpen(false);
        setBody({LinhaId: null, EquipmentId: null, Task: null, ActivityType: null, Who: null,
            FrequencyCode: null, InterventionTime: null, NumberOfPeople: null, PlanDate: minDate, MaintenanceType: null});
        setMaterials([])
    }

    const fetchLinhas = async () => {
        try {
            const response = await axiosInstance.get("linhas/get_linhas");
            setLinhas(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchEquipments = async () => {
        try {
            const response = await axiosInstance.get("equipments/get_all");
            setEquipments(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    const fetchFrequencies = async () => {
        try {
            const response = await axiosInstance.get("maintenance/get_frequencies");
            setFrequencies(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        const materialsBody = materials.map((material) => {
            return {MaterialId: material.item.id, Amount: material.amount}
        })

        const date = new Date(body.PlanDate).toISOString()

        let postBody = {};
        if (type === "plan")
        {
            postBody = {
                EquipmentId: body.EquipmentId,
                MaintenanceOrder: {
                    Task: body.Task,
                    EquipmentId: body.EquipmentId,
                    LinhaId: body.LinhaId,
                    PlanningType: 0,
                    MaintenanceType: (type === "plan" ? 1 : body.MaintenanceType),
                    // MaintenanceType: body.MaintenanceType,
                    FrequencyCode: (type === "plan" ? body.FrequencyCode : null),
                    ActivityType: body.ActivityType,
                    Planned: date,
                    TypePiece: 0,
                    Unit: 0,
                    Who: body.Who,
                    NumberOfPeople: body.NumberOfPeople,
                    InterventionTime: body.InterventionTime,
                    MaterialsMaintenanceOrders: materialsBody
                }
            }
        }
        else {
            postBody = {
                MaintenanceOrders: [
                    {
                        Task: body.Task,
                        EquipmentId: body.EquipmentId,
                        LinhaId: body.LinhaId,
                        PlanningType: 0,
                        // MaintenanceType: (type === "plan" ? 1 : body.MaintenanceType),
                        MaintenanceType: body.MaintenanceType,
                        FrequencyCode: (type === "plan" ? body.FrequencyCode : null),
                        ActivityType: body.ActivityType,
                        Planned: date,
                        TypePiece: 0,
                        Unit: 0,
                        Who: body.Who,
                        NumberOfPeople: body.NumberOfPeople,
                        InterventionTime: body.InterventionTime,
                        MaterialsMaintenanceOrders: materialsBody
                    }
                ]
            }
        }

        try {
            if (type !== "plan") {
                await axiosInstance.post("maintenance/add", postBody);
            } else {
                await axiosInstance.post("maintenance/add_order_to_plan", postBody)
            }

            await fetchOrders()
            handleOnClose()
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const date = new Date();

        setMinDate(ConvertToReadableDateInverted(date));
        handleSetBody("PlanDate", ConvertToReadableDateInverted(date));
        fetchLinhas();
        fetchEquipments();
        fetchFrequencies();

        if (type === "plan") {
            if (line !== null) {
                handleSetBody("LinhaId", line);
            }
            handleSetBody("EquipmentId", equipment);
        }
    }, []);

    return (
        <Sheet>
            <Modal keepMounted={false} open={open} onClose={handleOnClose}>
                <ModalOverflow>
                    <ModalDialog sx={{minWidth: "800px", maxWidth: "850px"}}>
                        <DialogTitle>
                            Criar ordem de manutenção
                        </DialogTitle>
                        <DialogContent>

                            {/*{line === null && (*/}
                            {/*    <Alert*/}
                            {/*        variant="soft"*/}
                            {/*        color="danger"*/}
                            {/*        invertedColors*/}
                            {/*        startDecorator={<ErrorOutlineOutlinedIcon/>}*/}
                            {/*        sx={{ alignItems: 'flex-start', gap: '1rem', maxWidth: "800px"}}*/}
                            {/*    >*/}
                            {/*        <Box sx={{ flex: 1 }}>*/}
                            {/*            <Typography level="title-md">Equipamento sem linha associada</Typography>*/}
                            {/*            <Typography level="body-md">*/}
                            {/*                O equipamento não tem nenhuma linha de produção associada. Por favor associe antes de criar uma ordem de manutenção.*/}
                            {/*            </Typography>*/}
                            {/*            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>*/}
                            {/*                <Button variant="outlined" size="sm" onClick={() => handleOnClose()}>*/}
                            {/*                    Fechar*/}
                            {/*                </Button>*/}
                            {/*                <Button variant="solid" size="sm">*/}
                            {/*                    Editar equipamento*/}
                            {/*                </Button>*/}
                            {/*            </Box>*/}
                            {/*        </Box>*/}
                            {/*    </Alert>*/}
                            {/*)}*/}
                            <form onSubmit={onSubmit}>
                                <Box sx={{mt: 2, gap: 2, display: "flex", flexDirection: "column"}}>
                                    <FormControl>
                                        <FormLabel>Descrição da tarefa</FormLabel>
                                        <Input
                                            placeholder="Insira a descrição da tarefa"
                                            required onChange={(event) => {
                                            handleSetBody("Task", event.target.value);
                                        }}/>
                                    </FormControl>
                                    <Box sx={{flexDirection: "row", display: "flex", gap: 2, width: "100%"}}>
                                        <FormControl sx={{flex: 1}}>
                                            <FormLabel>Linha</FormLabel>
                                            <Select
                                                // disabled={type === "plan"}
                                                disabled={line !== null}
                                                value={body.LinhaId}
                                                placeholder="Selecione a linha"
                                                required
                                                size={"md"}
                                                onChange={(event, newValue) => {
                                                    event.preventDefault()
                                                    handleSetBody("LinhaId", newValue);
                                                }}
                                                sx={{
                                                    color: "neutral",
                                                }}
                                                slotProps={{
                                                    listbox: {
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    },
                                                }}
                                            >
                                                {linhas?.map((linha) => (
                                                    <Option key={linha.id} value={linha.id}>
                                                        {linha.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{flex: 1}}>
                                            <FormLabel>Equipamento</FormLabel>
                                            <Select
                                                value={body.EquipmentId}
                                                // disabled={type === "plan"}
                                                placeholder="Selecione a máquina"
                                                required
                                                size={"md"}
                                                onChange={(event, newValue) => {
                                                    event.preventDefault()
                                                    handleSetBody("EquipmentId", newValue);
                                                }}
                                                sx={{
                                                    color: "neutral",
                                                }}
                                                slotProps={{
                                                    listbox: {
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    },
                                                }}
                                            >
                                                {equipments?.map((equipment) => (
                                                    <Option key={equipment.id} value={equipment.id}>
                                                        {equipment.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {type !== "plan" && (
                                        <Box sx={{flexDirection: "row", display: "flex", gap: 2, width: "100%", alignItems: "flex-end" }}>
                                            <FormControl sx={{flex: 1}}>
                                                <FormLabel>Tipo de manutenção</FormLabel>
                                                <Select
                                                    // disabled={type === "plan"}
                                                    placeholder="Selecione o tipo de manutenção"
                                                    required
                                                    size={"md"}
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        handleSetBody("MaintenanceType", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {maintenanceType?.map((type) => (
                                                        <Option key={type.Value} value={type.Value}>
                                                            {type.Name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{flex: 1 }}>
                                                <FormLabel>Quem</FormLabel>
                                                <Select
                                                    placeholder="Selecione o responsável pela manutenção"
                                                    required
                                                    size={"md"}
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        handleSetBody("Who", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Whos?.map((type) => (
                                                        <Option key={type.Value} value={type.Value}>
                                                            {type.Name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                    {type === "plan" && (
                                        <Box sx={{flexDirection: "row", display: "flex", gap: 2, alignItems: "flex-end"}}>
                                            <FormControl sx={{ flex: 1 }}>
                                                <FormLabel>Frequência de manutenção</FormLabel>
                                                <Select
                                                    placeholder="Selecione a frequência de manutenção"
                                                    required
                                                    size="md"
                                                    defaultValue={body.FrequencyCode}
                                                    onChange={(event, newValue) => {
                                                        handleSetBody("FrequencyCode", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {frequencies?.map((frequency) => (
                                                        <Option key={frequency.code} value={frequency.code}>
                                                            {frequency.description}
                                                        </Option>
                                                    ))}
                                                    {frequencyValuesList === null && <Option value={1}>1</Option>}
                                                </Select>
                                            </FormControl>
                                            {/*<FormControl sx={{ flex: 1 }}>*/}
                                            {/*    <FormLabel>Quem</FormLabel>*/}
                                            {/*    <Select*/}
                                            {/*        placeholder="Selecione o responsável pela manutenção"*/}
                                            {/*        required*/}
                                            {/*        size={"md"}*/}
                                            {/*        onChange={(event, newValue) => {*/}
                                            {/*            event.preventDefault();*/}
                                            {/*            handleSetBody("Who", newValue);*/}
                                            {/*        }}*/}
                                            {/*        sx={{*/}
                                            {/*            color: "neutral",*/}
                                            {/*        }}*/}
                                            {/*        slotProps={{*/}
                                            {/*            listbox: {*/}
                                            {/*                sx: {*/}
                                            {/*                    width: "100%",*/}
                                            {/*                },*/}
                                            {/*            },*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        {Whos?.map((type) => (*/}
                                            {/*            <Option key={type.Value} value={type.Value}>*/}
                                            {/*                {type.Name}*/}
                                            {/*            </Option>*/}
                                            {/*        ))}*/}
                                            {/*    </Select>*/}
                                            {/*</FormControl>*/}
                                        </Box>
                                    )}
                                    <Box sx={{flexDirection: "row", display: "flex", gap: 2, width: "100%"}}>
                                        <FormControl sx={{flex: 1}}>
                                            <FormLabel>Tipo de atividade</FormLabel>
                                            <Select
                                                placeholder="Selecione o tipo de atividade"
                                                required
                                                size={"md"}
                                                onChange={(event, newValue) => {
                                                    event.preventDefault()
                                                    handleSetBody("ActivityType", newValue);
                                                }}
                                                sx={{
                                                    color: "neutral",
                                                }}
                                                slotProps={{
                                                    listbox: {
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    },
                                                }}
                                            >
                                                {activityType?.map((type) => (
                                                    <Option key={type.Value} value={type.Value}>
                                                        {type.Name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl  sx={{flex: 1}}>
                                            <FormLabel>Data planeada</FormLabel>
                                            <Input
                                                required
                                                sx={{width: "100%"}}
                                                value={body.PlanDate}
                                                onChange={(event) => handleSetBody("PlanDate", event.target.value)}
                                                type="date"
                                                slotProps={{
                                                    input: {
                                                        min: `${minDate}`
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{flexDirection: "row", display: "flex", gap: 2, width: "100%"}}>
                                        <FormControl sx={{flex: 1}}>
                                            <FormLabel>Tempo de intervenção (minutos)</FormLabel>
                                            <Input
                                                placeholder="Selecione o tempo de intervenção"
                                                required
                                                value={body.InterventionTime}
                                                onChange={(e) => handleSetBody("InterventionTime", e.target.value)}
                                                type="number"
                                                min={"0"}
                                            />
                                        </FormControl>
                                        <FormControl sx={{flex: 1}}>
                                            <FormLabel>Número de pessoas</FormLabel>
                                            <Input
                                                placeholder="Selecione o número de pessoas"
                                                required
                                                value={body.NumberOfPeople}
                                                onChange={(e) => { handleSetBody("NumberOfPeople", e.target.value)}}
                                                type="number"
                                                min={"0"}
                                            />
                                        </FormControl>
                                    </Box>
                                    <AddMaterialFormComponent disabled={type === "plan"} materials={materials} setMaterials={setMaterials} materialType={"manutencao"}/>
                                    <Button type="submit">Criar manutenção</Button>
                                </Box>
                            </form>
                        </DialogContent>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
        </Sheet>
    );
}