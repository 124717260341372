import {
    Box, Button,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalDialog, Option,
    Select,
    Stack
} from "@mui/joy";
import axiosInstance from "../../utils/axiosInstance";
import {useState} from "react";

export default function CreateEquipmentDialog({setOpen, open, lines, currentPage, fetchEquipments}) {
    const [body, setBody] = useState({name: "", idLinha: null, year: 2024, serialNumber: null, model: ""});

    const handleClose = () => {
        setOpen(false)
    }

    const handleOnCreate = async (e) => {
        e.preventDefault();

        const postBody = {
            Name: body.name,
            ProductionLineId: body.idLinha,
            Year: body.year,
            SerialNumber: body.serialNumber,
            Model: body.model
        }

        try {
            const response = await axiosInstance.post("/equipments/create", postBody);

            fetchEquipments(currentPage, 16);
            setOpen(false);
        } catch (e) {
            console.error("Error creating equipment:", e);
        }

        setOpen(false);
    }

    return (
        <Stack>
            <Modal open={open} onClose={handleClose} >
                <ModalDialog sx={{minWidth: "450px"}}>
                    <DialogTitle>
                        Criar Equipamento
                    </DialogTitle>
                    <DialogContent sx={{pt: 2}}>
                        {/* Formulario de criação de equipamento */}
                        <form onSubmit={handleOnCreate}>
                            <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                <FormControl>
                                    <FormLabel>Nome do Equipamento</FormLabel>
                                    <Input
                                        type={"text"}
                                        required
                                        value={body.name}
                                        onChange={(e) => {
                                            setBody({...body, name: e.target.value})
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Ano</FormLabel>
                                    <Input
                                        type={"number"}
                                        required
                                        value={body.year}
                                        onChange={(e) => {
                                            setBody({...body, year: e.target.value})
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Número de série</FormLabel>
                                    <Input
                                        type={"number"}
                                        required
                                        value={body.serialNumber}
                                        onChange={(e) => {
                                            setBody({...body, serialNumber: e.target.value})
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Modelo</FormLabel>
                                    <Input
                                        type={"text"}
                                        required
                                        value={body.model}
                                        onChange={(e) => {
                                            setBody({...body, model: e.target.value})
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Linha de Produção</FormLabel>
                                    <Select
                                        onChange={(e, newValue) => {
                                            setBody({...body, idLinha: newValue})
                                        }}
                                        value={body.idLinha}
                                        required
                                    >
                                        <Option key={0} value={null}>
                                            Nenhum
                                        </Option>
                                        {lines.map((line) => (
                                            <Option key={line.id} value={line.id}>
                                                {line.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button type={"submit"}>Criar Equipamento</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </Stack>
    )
}